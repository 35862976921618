<template>
  <q-dialog :ref="$options.name" seamless position="bottom">
    <q-card>
      <q-card-section class="row items-center no-wrap">
        <q-icon :name="icon" :class="iconClass" class="q-mr-md" size="2em" />
        <h6 class="text-weight-medium">
          {{ message }}
        </h6>

        <q-space />

        <q-btn
          flat
          round
          icon="close"
          color="subtle"
          class="q-ml-lg"
          v-close-popup
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ConfirmationModalDelete",
  props: {
    type: {
      type: String,
      required: true
    },
    success: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    message() {
      let message = "";
      if (this.success) {
        if (this.type === "create") {
          message = "Successfully created.";
        } else if (this.type === "update") {
          message = "Successfully updated.";
        } else if (this.type === "delete") {
          message = "Successfully deleted.";
        } else if (this.type === "copy") {
          message = "Copied to clipboard.";
        } else if (this.type === "save") {
          message = "Successfully saved changes.";
        } else if (this.type === "add") {
          message = "Successfully added.";
        }
      } else {
        if (this.type === "create") {
          message = "An error occurred during creation.";
        } else if (this.type === "update") {
          message = "An error occurred during update.";
        } else if (this.type === "delete") {
          message = "An error occurred during deletion.";
        } else if (this.type === "copy") {
          message = "Copy to clipboard not support by browser.";
        } else if (this.type === "save") {
          message = "An error occurred while saving.";
        }
      }
      return message;
    },
    icon() {
      let icon = this.success ? "task_alt" : "error_outline";
      if (this.type === "copy") {
        icon = "content_copy";
      }
      return icon;
    },
    iconClass() {
      return this.success ? "text-positive" : "text-negative";
    }
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
      setTimeout(() => {
        this.hide();
      }, 3000);
    },
    hide() {
      if (this.$refs[this.$options.name]) {
        this.$refs[this.$options.name].hide();
      }
    },
    handleOkClick() {
      this.$emit("ok");
      this.hide();
    }
  }
};
</script>

<style scoped lang="scss"></style>
